import './FrameworkBlock.scss';

import React from 'react';
import { Link } from 'gatsby';
import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';
import { Framework, Term } from 'types';

interface Props {
  framework: Framework;
  terms: Term[];
}

/**
 * Link to a framework with a small thumbnail tree diagram. Use in pages listing
 * or linking to frameworks.
 */
const FrameworkBlock: React.FC<Props> = (props) => {
  const { framework, terms } = props;

  return (
    <Link
      className="FrameworkBlock"
      to={`/frameworks/${framework.identifier}/`}
    >
      {terms.length > 0 && (
        <div className="FrameworkBlock-thumbnail">
          <ThumbnailFrameworkGraph
            framework={framework}
            terms={terms}
            width={34}
            height={44}
          />
        </div>
      )}

      <div className="FrameworkBlock-title">{framework.name}</div>
    </Link>
  );
};

export default FrameworkBlock;
