import './FrameworksGrid.scss';

import React, { useMemo } from 'react';
import { sortBy } from 'lodash';

import FrameworkBlock from './FrameworkBlock';
import { Framework, Term } from 'types';

interface Props {
  frameworks: Framework[];
  terms: Term[];
  excludedFramework?: Framework;
}

/**
 * List of frameworks in a grid. Each has a small thumbnail graph of its terms
 * and links to the framework page.
 */
const FrameworkList: React.FC<Props> = (props) => {
  const { excludedFramework, frameworks, terms } = props;

  const termsForFrameworkWithID = (frameworkID: number): Term[] => {
    return terms.filter((t) => t.frameworkID === frameworkID);
  };

  const filteredFrameworks = useMemo(() => {
    return sortBy(
      frameworks.filter(
        (f) =>
          f.identifier === f.group &&
          (excludedFramework
            ? f.identifier !== excludedFramework.identifier
            : true)
      ),
      (f) => f.name
    );
  }, [excludedFramework, frameworks]);

  return (
    <div className="FrameworksGrid">
      {filteredFrameworks.map((framework) => (
        <FrameworkBlock
          key={framework.identifier}
          framework={framework}
          terms={termsForFrameworkWithID(framework.identifier)}
        />
      ))}
    </div>
  );
};

export default FrameworkList;
